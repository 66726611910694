export function verifyStartTime(value, startTime, endTime, callback, dayjs) {
    const hours = parseInt(value.substring(0, 2), 10);
    const minutes = parseInt(value.substring(3, 5), 10);

    if (dayjs().hour(endTime.substring(0, 2)).minute(endTime.substring(3, 5))
        <= dayjs().hour(startTime.substring(0, 2)).minute(startTime.substring(3, 5))) {
        callback(new Error('incorrect'));
    } else if (hours >= 1 && hours <= 22 && minutes >= 0 && minutes <= 60) {
        callback();
    } else {
        callback(new Error('01:00 - 22:59'));
    }
}

export function verifyEndTime(value, startTime, endTime, callback, dayjs) {
    const hours = parseInt(value.substring(0, 2), 10);
    const minutes = parseInt(value.substring(3, 5), 10);

    if (dayjs().hour(endTime.substring(0, 2)).minute(endTime.substring(3, 5))
        <= dayjs().hour(startTime.substring(0, 2)).minute(startTime.substring(3, 5))) {
        callback(new Error('incorrect'));
    } else if (hours >= 2 && hours <= 23 && minutes >= 0 && minutes <= 60) {
        callback();
    } else {
        callback(new Error('02:00 - 23:59'));
    }
}
